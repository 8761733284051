<template>
  <ModernTable
    :region="priorOwnersTable.region"
    :filters="priorOwnersTable.filters"
    :rows="list"
    :columns="priorOwnersTable.columns"
    headerColor="#FFF"
    condensed
  />
</template>

<script>
import { getList as getOwnerList } from '@/services/Roster/Owner/getList'
import { getUnit } from '@/services/Roster/Unit/getUnit'
import ModernTable from '@/components/tables/Modern/Table.vue'
import SimpleButton from '@/components/buttons/Simple'
import { parseDate } from '@/utilities/Date/parse'
import _get from 'lodash/get'
import kms from '@/services/kms'
import { notifyProblem } from '@/services/notify'

export default {
  props: {
    kms: {
      type: Object,
      default() {
        return kms
      }
    }
  },
  components: {
    ModernTable
  },
  data() {
    return {
      ownerContacts: [],
      list: [],
      priorOwnersTable: {
        region: {},
        filters: {
          show: false
        },
        rows: [],
        columns: [
          {
            field: 'name',
            label: 'Prior Owner Name',
            aria: 'Prior Owner Name',
            width: '35%',
            sortable: true,
            searchable: true
          },
          {
            field: 'moveOutDate',
            date: true,
            label: 'Move-out Date',
            aria: 'Move-out Date',
            width: '10%',
            sortable: true,
            searchable: true
          },
          {
            field: 'actions',
            label: 'Actions',
            width: '5%'
          }
        ]
      }
    }
  },
  methods: {
    parsePriorOwners({ list }) {
      this.list = list
    },

    async init() {
      const unitID = _get(this, ['$route', 'params', 'id'], null)

      const { owners, exception } = await getOwnerList({
        unitID,
        kms: this.kms
      })
      if (exception) {
        console.error(exception)
        notifyProblem('A problem occurred while attempting to retrieve the list of prior owners.')
        return
      }

      const { unit, exception: getUnitException } = await getUnit({
        unitID,
        kms: this.kms
      })
      if (getUnitException) {
        console.error(getUnitException)
        notifyProblem(
          'A problem occurred while attempting to retrieve details about the prior owners.'
        )
        return
      }

      const primaryOwnerID = _get(unit, 'primaryOwnerID', null)
      const priorOwners = []

      const dateFormat = 'LL'

      for (let a = 0; a < owners.length; a++) {
        const owner = owners[a]
        const ownerId = _get(owner, 'ownerID', null)

        if (ownerId !== primaryOwnerID) {
          const moveOutDate = parseDate(_get(owner, 'moveOutDate', ''), dateFormat)

          priorOwners.push({
            name: owner.name,
            moveOutDate,
            actions: {
              component: SimpleButton,
              props: {
                to: {
                  name: 'unit.overview',
                  params: {
                    id: _get(unit, 'unitID', null),
                    ownerid: ownerId,
                    previousownerid: primaryOwnerID
                  }
                }
              }
            }
          })
        }
      }

      this.parsePriorOwners({
        list: priorOwners
      })

      this.priorOwnersTable.rows = this.parsePriorOwners
    },

    async redirectToOwner(ownerID) {
      if (ownerID) {
        console.debug('redirectToOwner owner clicked=' + ownerID)

        this.$router
          .push({
            name: 'unit.overview',
            params: {
              id: _get(this, ['$route', 'params', 'id'], null),
              ownerid: ownerID,
              previousownerid: 0
            }
          })
          .catch(() => {})
      }
    }
  },

  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;
  margin-bottom: 10px;
  padding: 22px;
}
</style>
