// /LeaseDocument/LeaseDocument/{leaseDocumentID}

/*
  example:
    import { deleteLeaseDocument } from '@/services/LeaseDocument/LeaseDocument/Delete';

    const { result, successful, message } = await deleteLeaseDocument ({
      leaseDocumentID
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
//
export async function deleteLeaseDocument({ leaseDocumentID }) {
  const message = 'The lease document could not be deleted.'

  try {
    const result = await kms.delete(`/LeaseDocument/LeaseDocument/${leaseDocumentID}`)

    return {
      successful: true,
      message: '',
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    result: []
  }
}

///////
