/*
  example:
    import { listLeaseDocuments } from '@/services/LeaseDocument/LeaseDocument/List';

    const { list, successful, message } = await listLeaseDocuments ({
      params: {
        leaseID
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import _get from 'lodash/get'
//
import kms from '@/services/kms'
//
export async function listLeaseDocuments({ params }) {
  const message = 'The lease documents list could not be retrieved.'

  try {
    const result = await kms.get(`/LeaseDocument/LeaseDocument/List`, {
      params
    })

    let list = _get(result, 'results', [])
    if (!Array.isArray(list)) {
      list = []
    }

    return {
      successful: true,
      message: '',
      list
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    list: []
  }
}

///////
