<template>
  <PageContentLayoutOnly :aria-label="$t('leases.title')" role="region">
    <b-button
      add-lease-button
      type="is-primary"
      class="is-rounded"
      @click="openAddLeaseModal()"
      :style="{ marginBottom: '20px' }"
      >Add Lease</b-button
    >

    <b-tabs position="is-left" type="is-boxed" class="block whitecolor" v-model="activeTab">
      <b-tab-item label="Active">
        <ModernTable
          active-table
          headerColor="#FFF"
          :expandableRows="true"
          :columns="activeTable.columns"
          :rows="activeTable.rows"
          :region="activeTable.region"
          :filters="activeTable.filters"
          :pagination="{
            perPage: 30
          }"
          :headerZIndex="10"
          condensed
          :useLoader="false"
        />
      </b-tab-item>

      <b-tab-item label="Terminated">
        <ModernTable
          terminated-table
          headerColor="#FFF"
          :expandableRows="true"
          :columns="terminatedTable.columns"
          :rows="terminatedTable.rows"
          :region="terminatedTable.region"
          :filters="terminatedTable.filters"
          :pagination="{
            perPage: 30
          }"
          :headerZIndex="10"
          condensed
          :useLoader="false"
        />
      </b-tab-item>
    </b-tabs>

    <Modal :toggle.sync="showLeaseModal" lease-modal>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear v-if="action == 'update' || action == 'add'">
          <form
            @submit.prevent="handleSubmit(onFormSubmit)"
            class="form"
            v-if="formData.LeaseTenantID <= 0"
          >
            <fieldset>
              <valid-input
                type="hidden"
                vid="leaseID"
                v-model="formData.leaseID"
                :style="{ visibility: 'hidden', position: 'absolute' }"
              />

              <div class="column is-12" :style="styles.datePickerContainer">
                <DatePicker
                  v-model="formData.startDate"
                  :errorMessage.sync="formErrors.startDate"
                  ariaLabel="Lease Start Date"
                  label="Start Date"
                  :style="styles.datePicker"
                  :startEmpty="clearStartDate"
                />

                <b-button rounded @click="clear('startDate')">clear</b-button>
              </div>
              <div class="column is-12" :style="styles.datePickerContainer">
                <DatePicker
                  v-model="formData.endDate"
                  :errorMessage.sync="formErrors.endDate"
                  ariaLabel="Lease End Date"
                  label="End Date"
                  :style="styles.datePicker"
                  :startEmpty="clearEndDate"
                />

                <b-button rounded @click="clear('endDate')">clear</b-button>
              </div>
              <div class="column is-12" :style="styles.datePickerContainer">
                <DatePicker
                  v-model="formData.terminationDate"
                  ariaLabel="Lease Termination Date"
                  label="Termination Date"
                  :style="styles.datePicker"
                  :startEmpty="clearTerminationDate"
                />

                <b-button rounded @click="clear('terminationDate')">clear</b-button>
              </div>
              <div class="column is-12">
                <valid-input
                  style="width:50px !important;"
                  name="Notes"
                  label="Notes"
                  maxlength="50"
                  type="text"
                  vid="Notes"
                  placeholder="Notes"
                  spellcheck="true"
                  aria-label="Notes"
                  rules="max:50|required"
                  class="is-small"
                  v-model="formData.Notes"
                />
              </div>
              <div class="column is-12">
                <button
                  add
                  v-if="action && action == 'add'"
                  type="submit"
                  :disabled="loading"
                  class="button is-primary is-rounded"
                  :style="{ float: 'right' }"
                >
                  Add
                </button>
                <button
                  update
                  v-if="action && action == 'update'"
                  @click.prevent="updateLease(selectedLease, selectedID)"
                  class="button is-primary is-rounded"
                  :style="{ float: 'right' }"
                >
                  Update
                </button>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </Modal>

    <Modal :toggle.sync="showTenantModal">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition
          name="fade"
          mode="out-in"
          appear
          v-if="action == 'updatetenant' || action == 'addtenant'"
        >
          <form @submit.prevent="handleSubmit(addLeaseTenant)" class="form">
            <fieldset>
              <label class="title is-7 tooltip-label">{{ formData.Notes }}</label>
              <valid-input
                type="hidden"
                vid="leaseTenantID"
                v-model="formData.LeaseTenantID"
                :style="{ position: 'absolute', visibility: 'hidden' }"
              />
              <valid-input
                type="hidden"
                vid="leaseID"
                v-model="formData.LeaseID"
                :style="{ position: 'absolute', visibility: 'hidden' }"
              />
              <div class="column is-12">
                <valid-input
                  v-model="formData.LeaseTenantName"
                  :message="formErrors.LeaseTenantName"
                  style="width:50px !important;"
                  name="LeaseTenantName"
                  label="Name"
                  maxlength="100"
                  type="text"
                  vid="LeaseTenantName"
                  placeholder="Lease Tenant Name"
                  spellcheck="true"
                  aria-label="Name"
                  rules="max:100|required"
                  class="is-small"
                  required
                />
              </div>
              <div class="column is-12">
                <valid-input
                  v-model="formData.LeaseTenantEmail"
                  :message="formErrors.LeaseTenantEmail"
                  style="width:50px !important;"
                  name="LeaseTenantEmail"
                  label="Email"
                  maxlength="255"
                  type="email"
                  vid="LeaseTenantEmail"
                  placeholder="Lease Tenant Email"
                  spellcheck="true"
                  aria-label="Email"
                  rules="max:255"
                  class="is-small"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  v-model="formData.LeaseTenantPhone"
                  :message="formErrors.LeaseTenantPhone"
                  style="width:50px !important;"
                  name="LeaseTenantPhone"
                  label="Phone"
                  maxlength="20"
                  type="phone"
                  vid="LeaseTenantPhone"
                  placeholder="Lease Tenant Phone"
                  spellcheck="true"
                  aria-label="Phone"
                  rules="max:20"
                  class="is-small"
                />
              </div>
              <div class="column is-12">
                <button
                  v-if="action && action == 'addtenant'"
                  type="submit"
                  :disabled="loading"
                  class="button is-primary is-rounded float-right"
                >
                  Add
                </button>
                <button
                  v-if="action && action == 'updatetenant'"
                  @click.prevent="updateLeaseTenant(selectedLease, selectedID)"
                  class="button is-primary is-rounded float-right"
                >
                  Update
                </button>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </Modal>

    <DocumentModal ref="documentModal" :leaseID="formData.leaseID" :onUpload="documentsUpdated" />
  </PageContentLayoutOnly>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState } from 'vuex'

// components
import ValidInput from '@/components/inputs/ValidInput'
import ModernTable from '@/components/tables/Modern/Table'
import Modal from '@/components/Modal'
import DatePicker from '@/components/inputs/DatePicker'
import DocumentModal from './components/DocumentModal'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import kms from '@/services/kms'
import { data } from './keys/data'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'
import { watch } from './keys/watch'

export const defaultDateFormat = 'MM/dd/yyy'

export default {
  components: {
    DocumentModal,
    DatePicker,
    Modal,
    ModernTable,
    PageContentLayoutOnly,
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  props: {
    // for the purpose of testing
    kms: {
      type: Object,
      default: () => {
        return kms
      }
    },
    unitTestID: {
      type: Number,
      default: null
    }
  },

  data,
  watch,
  methods,
  mounted,

  created() {
    this.getRefreshedLeases()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  i18n: {
    messages: {
      en: { leases: { title: 'Lease Information' } }
    }
  }
}
</script>
