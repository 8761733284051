// import _get from 'lodash/get'
import { leaseDocumentStore } from '@/services/LeaseDocument/LeaseDocument/store'

export function mounted() {
  this.updateRows()
  this.refresh()

  this.unsubscribeLeaseDocumentStore = leaseDocumentStore.subscribe(mutation => {
    const mType = mutation.type

    if (mType === 'documentsStored') {
      this.refresh()
    }
  })
}
