<template>
  <span
    :style="{
      whiteSpace: 'no-wrap',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }"
  >
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      @click="loadUpdateLease(leaseID)"
    >
      Edit</button
    >&nbsp;
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      @click="confirmDeleteLease(leaseID)"
    >
      Delete
    </button>
  </span>
</template>

<script>
export default {
  props: {
    leaseID: Number,
    confirmDeleteLease: Function,
    loadUpdateLease: Function,
    loadLeaseTenant: Function,
    openDocumentModal: Function
  },
  data() {
    const leaseId = this.leaseID

    return {
      detailsLocation: {
        name: 'unit.leaseInformation.details',
        params: {
          leaseId,
          id: 15641
        }
      }
    }
  }
}
</script>
