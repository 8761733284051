import _get from 'lodash/get'
//
import { parseDate } from '@/utilities/Date/parse'
//
import Actions from '../../../components/Actions'
import Details from '../../../components/Details'
//
export const updateRows = function({ table, list }) {
  const dateFormat = 'LL'

  this[table].rows = this[list].map(lease => {
    const id = _get(lease, 'leaseID', '')
    const leaseTenants = _get(lease, 'leaseTenants', '')

    const parsed = {
      _expandable: {
        show: true,
        expanded: true,
        component: Details,
        props: {
          leaseID: id,
          leaseTenants,
          loadUpdateLeaseTenant: (_id, _name) => {
            this.loadUpdateLeaseTenant(_id, _name)
          },
          confirmDeleteLeaseTenant: (_id, _name) => {
            this.confirmDeleteLeaseTenant(_id, _name)
          },
          addTenant: () => {
            this.openTenantModal({
              leaseID: id,
              action: 'add'
            })
          },
          addDocuments: () => {
            this.openDocumentModal({
              id
            })
          }
        }
      },

      id,
      startDate: {
        date: parseDate(_get(lease, 'startDate', ''), dateFormat),
        format: dateFormat
      },
      endDate: {
        date: parseDate(_get(lease, 'endDate', ''), dateFormat),
        format: dateFormat
      },
      terminationDate: {
        date: parseDate(_get(lease, 'terminationDate', ''), dateFormat),
        format: dateFormat
      },
      notes: _get(lease, 'notes', ''),
      modifiedDate: {
        date: parseDate(_get(lease, 'modifiedDate', ''), dateFormat),
        format: dateFormat
      },
      actions: {
        component: Actions,
        props: {
          leaseID: id,
          loadLeaseTenant: _id => {
            this.openTenantModal({
              leaseID: _id,
              action: 'add'
            })
          },
          loadUpdateLease: _id => {
            this.updateSelected({
              id: _id,
              list
            })

            this.loadUpdateLease(_id)
          },
          confirmDeleteLease: _id => {
            this.confirmDeleteLease(_id)
          }
        }
      }
    }

    return parsed
  })
}
