/*
  import { getMultiple } from '@/services/LeaseDocument/LeaseDocument/sequences/GetMultiple';

  const { docs, problems } = await getMultiple ({
    paramsList: [{
  
    }]
  });
  if (!successful) {

  }
*/

import _get from 'lodash/get'
//
import { getLeaseDocument } from '../GetDocumentFile'
import { getLeaseDocumentData } from '../Get'
//
export async function getMultiple({ paramsList }) {
  const results = await Promise.all(
    paramsList.map(params => {
      return new Promise(async resolve => {
        const leaseDocumentID = params.leaseDocumentID

        const {
          result: resultData,
          successful: getSuccessful,
          message: dataMessage
        } = await getLeaseDocumentData({
          leaseDocumentID
        })
        if (!getSuccessful) {
          console.error(dataMessage)
          resolve({
            successful: false
          })
          return
        }

        const description = _get(resultData, 'description', '')
        const documentId = _get(resultData, 'document.documentId', 0)

        const { doc, successful, message } = await getLeaseDocument({
          params
        })
        if (!successful) {
          console.error(message)
          resolve({
            successful: false
          })
          return
        }

        console.log({
          successful,
          message
        })

        resolve({
          doc,
          description,
          leaseDocumentID,
          documentId,
          successful: true
        })
      })
    })
  )

  const problems = []
  const retrievals = []
  for (let a = 0; a < results.length; a++) {
    const result = results[a]
    const { successful } = result

    if (successful === true) {
      retrievals.push({
        content: result.doc,
        description: result.description,
        leaseDocumentID: result.leaseDocumentID,
        documentId: result.documentId
      })
    } else {
      problems.push(result)
    }
  }

  const formData = new FormData()
  formData.append('file', retrievals[0])

  return {
    docs: retrievals,
    problems
  }
}
