/*
  example:
    import { getList } from '@/services/Roster/Owner/getList';

    const { owners, exception } = await getList ({
      unitID
    });
    if (exception) {
      console.error (exception);
      return;
    }
*/

/*
  possible structure

  {
    HOAs: {

      // hoa id
      0: {

        // unit id
        0: {
          owners: []
        }
      }
    }
  }
*/
//
import kms from '@/services/kms'
import _get from 'lodash/get'
//
export async function getList({ unitID, kms: kms_ = kms } = {}) {
  var result = {}
  try {
    result = await kms_.get(`/Roster/Owner/List`, {
      params: {
        unitID,
        includeDeleted: false
      }
    })
  } catch (exception) {
    console.error(exception)

    return {
      owners: [],
      exception
    }
  }

  return {
    owners: _get(result, 'results', []),
    exception: null
  }
}

///////
