import _get from 'lodash/get'
import DetailsActions from './../components/DetailsActions'
import { listLeaseDocuments } from '@/services/LeaseDocument/LeaseDocument/List'
import { getMultiple } from '@/services/LeaseDocument/LeaseDocument/sequences/GetMultiple'
import { leaseDocumentStore } from '@/services/LeaseDocument/LeaseDocument/store'
import { notifyProblem } from '@/services/notify'
import { download } from '@/utilities/download'

export const methods = {
  downloadDocument({ doc }) {
    const { description, content } = doc

    download({
      name: description,
      content
    })
  },

  confirmEraseDocument({ doc }) {
    const { description, leaseDocumentID } = doc

    this.$buefy.dialog.confirm({
      title: 'Document Deletion',
      message: `Are you sure you want to <b>delete</b> this document, '${description}'?`,
      confirmText: 'Delete',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.eraseDocument({ leaseDocumentID })
    })
  },

  async eraseDocument({ leaseDocumentID }) {
    this.loading = true

    await leaseDocumentStore.dispatch('deleteOne', {
      leaseID: this.leaseID,
      leaseDocumentID
    })

    await this.refresh()

    this.loading = false
  },

  updateRows: function() {
    if (!Array.isArray(this.leaseTenants)) {
      return
    }

    this.rows = this.leaseTenants.map(entry => {
      const name = _get(entry, 'name', '')

      return {
        name,
        phone: _get(entry, 'phone', ''),
        email: _get(entry, 'email', ''),
        actions: {
          component: DetailsActions,
          props: {
            item: entry,
            leaseTenantID: _get(entry, 'leaseTenantID', ''),
            name,
            loadUpdateLeaseTenant: (_id, _item) => {
              this.loadUpdateLeaseTenant(_id, _item)
            },
            confirmDeleteLeaseTenant: (_id, _name) => {
              this.confirmDeleteLeaseTenant(_id, _name)
            }
          }
        }
      }
    })
  },

  async getDocuments({ list }) {
    console.debug('documents list=' + JSON.stringify(list))

    if (!Array.isArray(list) || list.length === 0) {
      this.documents = []
      return
    }

    this.loading = true

    const paramsList = list.map(entry => {
      return {
        leaseDocumentID: entry.leaseDocumentID,
        autoMimeType: true,
        asBase64: true
      }
    })

    const { docs, problems } = await getMultiple({
      paramsList
    })
    if (problems.length >= 1) {
      const count = problems.length
      notifyProblem(
        `A problem occurred and ${problems.length} document${
          count === 1 ? '' : 's'
        } could not be displayed.`
      )
    }

    this.documents = docs

    //console.debug('docs=' + JSON.stringify(docs))

    await new Promise(resolve => {
      setTimeout(() => {
        this.loading = false
        resolve()
      }, 500)
    })
  },

  async openPDFModal(id, description) {
    console.debug('in openPDFModal...' + id)
    if (this.documents) {
      for (let a = 0; a < this.documents.length; a++) {
        const document = this.documents[a]

        console.debug('document id=' + id + ', document id=' + document.documentId)

        if (document.documentId === id) {
          this.$refs.pdfModal.open({
            src: this.documents[a].content
          })

          this.$refs.pdfModal.setTitle({ pdfTitle: description })
          return
        }
      }
    }
  },

  async refresh() {
    const { list, successful, message } = await listLeaseDocuments({
      params: {
        leaseID: this.leaseID
      }
    })
    if (!successful) {
      console.debug(message)
      return
    }

    await this.getDocuments({
      list
    })
  }
}
