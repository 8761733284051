<template>
  <div>
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      @click="loadUpdateLeaseTenant(leaseTenantID, item)"
    >
      Edit</button
    >&nbsp;
    <button
      class="button is-info is-light is-small is-outline is-rounded"
      @click="confirmDeleteLeaseTenant(leaseTenantID, name)"
    >
      Delete
    </button>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
    leaseTenantID: [Number, String],
    name: String,
    loadUpdateLeaseTenant: Function,
    confirmDeleteLeaseTenant: Function
  }
}
</script>
