<template>
  <div>
    <div
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px'
      }"
    >
      <h4 :style="{ margin: 0 }">Tenants</h4>
      <b-button class="is-rounded" type="is-primary" @click="addTenant">
        Add Tenant
      </b-button>
    </div>

    <ModernTable height="initial" condensed :columns="columns" :rows="rows" :useLoader="false" />

    <div
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px'
      }"
    >
      <h4 :style="{ margin: 0 }">Documents</h4>
      <b-button type="is-primary" class="is-rounded" @click="addDocuments({ leaseID })">
        Add Documents
      </b-button>
    </div>

    <PDFModal ref="pdfModal" />
    <div
      class="pb-5"
      :style="{ position: 'relative', display: 'flex', flexWrap: 'wrap', width: '100%' }"
    >
      <div
        v-for="(doc, index) in documents"
        :key="index"
        :style="{
          position: 'relative',
          width: '200px',
          height: '100px',
          background: '#FFF',
          borderRadius: '4px',
          margin: '10px',
          boxShadow: `0 0 4px 1px #E6E6E6`
        }"
      >
        <div
          :style="{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            overflow: 'hidden'
          }"
        >
          <PDF style="display: none;" :src="base64pdf" @num-pages="pageCount = new Array($event)" />
          <!-- Actual document -->
          <PDF
            v-for="(page, index) in pageCount"
            :key="index"
            :ref="'pdf-' + index"
            :src="doc.content"
            :page="index + 1"
            :scale="1"
            :resize="true"
            :style="{
              margin: 'auto',
              width: `${100 * 1}%`,
              opacity: loading ? 0 : 1,
              transition: transition ? '.2s opacity' : ''
            }"
          />
        </div>

        <b-tooltip :style="{ position: 'absolute', top: '10px', left: '10px' }" label="Delete">
          <button
            :style="{ position: 'relative' }"
            class="delete"
            type="button"
            @click="confirmEraseDocument({ index, doc })"
          />
        </b-tooltip>

        <b-tooltip label="Preview">
          <button
            class="view"
            type="button"
            @click.prevent="openPDFModal(doc.documentId, doc.description)"
          >
            <span class="pl-6 href-overflow-wrap centerpreview" style="font-size: 8pt;">{{
              doc.description || 'Preview'
            }}</span>
          </button>
        </b-tooltip>

        <b-tooltip :style="{ position: 'absolute', top: '10px', right: '10px' }" label="Download">
          <button type="button" @click="downloadDocument({ index, doc })">
            <b-icon icon="download" size="is-small" :style="{ color: 'rgba(10, 10, 10, 0.2)' }">
            </b-icon>
          </button>
        </b-tooltip>
      </div>

      <div
        v-if="documents.length === 0"
        class="has-text-grey has-text-centered"
        :style="{ padding: '20px' }"
      >
        No documents added
      </div>

      <b-loading :is-full-page="false" v-model="loading" />
    </div>
  </div>
</template>

<script>
import ModernTable from '@/components/tables/Modern/Table'
import PDFModal from '@/components/Modals/PDF'
import PDF from 'vue-pdf'
import { methods } from './keys/methods'
import { mounted } from './keys/mounted'

export default {
  components: {
    ModernTable,
    PDFModal,
    PDF
  },
  props: {
    leaseTenants: {
      type: Array,
      default: () => {
        return []
      }
    },
    loadUpdateLeaseTenant: Function,
    confirmDeleteLeaseTenant: Function,
    addTenant: Function,
    addDocuments: Function,
    leaseID: Number
  },
  data: function() {
    return {
      transition: true,
      columns: [
        {
          field: 'name',
          label: 'Tenant Name'
        },
        {
          field: 'phone',
          label: 'Phone'
        },
        {
          field: 'email',
          label: 'Email'
        },
        {
          field: 'actions',
          label: 'Actions'
        }
      ],
      rows: [],
      documents: [],
      loading: false,
      pageCount: []
    }
  },
  methods,
  watch: {
    leaseTenants: function() {
      this.updateRows()
    }
  },
  mounted
}
</script>

<style>
.centerpreview {
  white-space: normal;
  text-align: center;
  width: 50px;
}
</style>
