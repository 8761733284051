/*
  Phone (case insensitive) -> LeaseTenantPhone
  Email (case insensitive) -> LeaseTenantEmail
*/

import _get from 'lodash/get'
import { notifyProblem } from '@/services/notify'

export function showFormValidationErrors({ exception }) {
  const fields = _get(exception, ['fields'], null)

  this.clearFormErrors()

  const problems = []
  for (let key in fields) {
    const problem = _get(fields, [key], []).join(' ')
    problems.push(problem)

    // formErrors mappings
    if (key.toLowerCase() === 'phone') {
      key = 'LeaseTenantPhone'
    } else if (key.toLowerCase() === 'email') {
      key = 'LeaseTenantEmail'
    } else {
      console.error(`server error field "${key}" was not anticipated`)
    }
    this.formErrors[key] = problem
  }

  const problemsMessage = problems.join(' ')
  if (problemsMessage.length === 0) {
    notifyProblem(exception)
  } else {
    notifyProblem(problems.join(' '))
  }
}
