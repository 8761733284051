/*
  import { StoreMultiple } from '@/services/LeaseDocument/LeaseDocument/sequences/StoreMultiple';

  const { successful, documentIds } = await StoreMultiple ({
    leaseID,
    documents: []
  });
  if (!successful) {

  }

*/
//
import { storeLeaseDocument } from '../StoreDocumentFile'
import { addLeaseDocument } from '@/services/LeaseDocument/LeaseDocument/Post'
import { deleteLeaseDocument } from '@/services/LeaseDocument/LeaseDocument/Delete'

//
import { notifyProblem } from '@/services/notify'
//
export async function StoreMultiple({ leaseID, documents }) {
  const results = await Promise.all(
    documents.map(doc => {
      return new Promise(async resolve => {
        const { leaseDocumentID, successful, message } = await addLeaseDocument({
          json: {
            leaseID,
            description: doc.name
          }
        })
        if (!successful) {
          notifyProblem(message)

          const {
            successful: deletionSuccessful,
            message: deletionMessage
          } = await deleteLeaseDocument({
            leaseDocumentID
          })
          if (!deletionSuccessful) {
            console.error(deletionMessage)
          }

          resolve({
            successful: false
          })
          return
        }

        const { successful: storeSuccessful, message: storeMessage } = await storeLeaseDocument({
          document: doc,
          params: {
            leaseID,

            // optional
            existingLeaseDocumentID: leaseDocumentID
          }
        })
        if (!storeSuccessful) {
          notifyProblem(storeMessage)

          const {
            successful: deletionSuccessful,
            message: deletionMessage
          } = await deleteLeaseDocument({
            leaseDocumentID
          })
          if (!deletionSuccessful) {
            console.error(deletionMessage)
          }

          resolve({
            successful: false
          })
          return
        }

        resolve({
          successful: true,
          leaseDocumentID
        })
      })
    })
  )

  const problems = []
  const uploads = []
  for (let a = 0; a < results.length; a++) {
    const result = results[a]
    const { successful, leaseDocumentID } = result

    if (successful === false) {
      problems.push(result)
    }

    if (successful === true) {
      uploads.push(leaseDocumentID)
    }
  }

  return {
    uploads,
    problems
  }
}
