/*
	import { download } from '@/utilities/download'

	download ({
		name
		content
	})
*/

export function download({ name, content }) {
  var a = document.createElement('a')
  a.href = content
  a.download = name
  a.click()
}
