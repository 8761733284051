/*
  import { leaseDocumentStore } from '@/services/LeaseDocument/LeaseDocument/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { StoreMultiple } from '@/services/LeaseDocument/LeaseDocument/sequences/StoreMultiple'
import { deleteLeaseDocument } from '@/services/LeaseDocument/LeaseDocument/Delete'

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { uploads, problems } = await leaseDocumentStore.dispatch ('storeMultiple', {
      leaseID,
      documents
    });
  */
  async storeMultiple({ commit }, { leaseID, documents }) {
    const { uploads, problems } = await StoreMultiple({
      leaseID,
      documents
    })
    if (uploads.length >= 1) {
      commit('documentsStored')
    }

    return {
      uploads,
      problems
    }
  },

  /*
    await leaseDocumentStore.dispatch ('deleteOne', {
      leaseDocumentID
    });
  */
  async deleteOne({ commit }, { leaseDocumentID }) {
    const { successful } = await deleteLeaseDocument({
      leaseDocumentID
    })
    if (successful) {
      commit('documentDeleted')
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const leaseDocumentStore = new Vuex.Store({
  state: {
    /*
      e.g. documents [ leaseId ] = []
    */
    documents: {}
  },
  mutations: {
    documentDeleted() {},
    documentsStored() {},
    /*
      this.unsubscribeLeaseDocumentStore = leaseDocumentStore.subscribe(mutation => {
        const mType = mutation.type

        if (mType === 'updateDocuments') {

        }
      })
    */
    updateDocuments(state, { leaseID, list }) {
      state.documents[leaseID] = list
    }
  },
  getters: {},
  actions
})
