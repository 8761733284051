import moment from 'moment'

export const data = function() {
  let DEFAULT_FORM_VALUES = {
    leaseID: 0,
    unitID: 0,
    startDate: null,
    endDate: null,
    terminationDate: null,
    notes: '',
    leaseTenantID: 0,
    leaseTenantName: '',
    leaseTenantPhone: '',
    leaseTenantEmail: ''
  }

  let DEFAULT_FORM_ERRORS = {
    startDate: null,
    endDate: null,
    LeaseTenantName: '',
    LeaseTenantPhone: '',
    LeaseTenantEmail: ''
  }

  return {
    activeTab: 0,
    DEFAULT_FORM_VALUES,
    DEFAULT_FORM_ERRORS,
    formErrors: {
      ...DEFAULT_FORM_ERRORS
    },
    formData: {
      ...DEFAULT_FORM_VALUES
    },
    formComplete: false,
    loading: true,
    isDebug: true,
    confirmModal: false,
    formatDateFn: function(value) {
      return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
    },
    formatFn: function(value) {
      return value != null ? value : ''
    },
    formatNumberFn: function(value) {
      return value != null ? value : 0
    },
    selectedRow: null,
    leaseList: [{}],
    terminatedLeaseList: [{}],
    unitID: null,
    selectedID: null,
    selectedLeaseID: null,
    selectedLease: null,
    selectedLeaseTenant: null,
    formShow: false,
    action: '',
    showWeekNumber: false,
    showDetailIcon: true,
    selectedParentClass: 'is-selected',
    normalParentClass: '',
    clearStartDate: false,
    clearEndDate: false,
    clearTerminationDate: false,
    showLeaseModal: false,
    showTenantModal: false,
    showDocumentModal: false,

    activeTable: {
      region: {},
      filters: {
        show: false
      },
      expandableRows: true,
      columns: [
        {
          field: 'id',

          label: 'ID',
          aria: 'ID',
          width: '5%',
          numeric: true,
          sortable: true,
          searchable: true
        },
        {
          field: 'startDate',
          date: true,

          label: 'Start Date',
          aria: 'Start Date',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'endDate',
          date: true,

          label: 'End Date',
          aria: 'End Date',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'terminationDate',
          date: true,

          label: 'Termination Date',
          aria: 'Termination Date',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'notes',

          label: 'Notes',
          aria: 'Notes',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'modifiedDate',
          date: true,

          label: 'Modification Date',
          aria: 'Modification Date',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'actions',
          label: 'Actions',
          aria: 'Actions',
          width: '5%'
        }
      ],
      rows: []
    },

    terminatedTable: {
      region: {},
      filters: {
        show: false
      },
      columns: [
        {
          field: 'id',

          label: 'ID',
          aria: 'ID',
          width: '5%',
          numeric: true,
          sortable: true,
          searchable: true
        },
        {
          field: 'startDate',
          date: true,

          label: 'Start Date',
          aria: 'Start Date',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'endDate',
          date: true,

          label: 'End Date',
          aria: 'End Date',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'terminationDate',
          date: true,

          label: 'Termination Date',
          aria: 'Termination Date',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'notes',

          label: 'Notes',
          aria: 'Notes',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'modifiedDate',
          date: true,

          label: 'Modification Date',
          aria: 'Modification Date',
          width: '5%',
          sortable: true,
          searchable: true
        },
        {
          field: 'actions',
          label: 'Actions',
          aria: 'Actions',
          width: '5%'
        }
      ],
      rows: []
    },

    styles: {
      datePickerContainer: { display: 'flex', alignItems: 'end' },
      datePicker: { margin: 0, marginRight: '12px' }
    }
  }
}
