var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '12px'
    })},[_c('h4',{style:({ margin: 0 })},[_vm._v("Tenants")]),_c('b-button',{staticClass:"is-rounded",attrs:{"type":"is-primary"},on:{"click":_vm.addTenant}},[_vm._v(" Add Tenant ")])],1),_c('ModernTable',{attrs:{"height":"initial","condensed":"","columns":_vm.columns,"rows":_vm.rows,"useLoader":false}}),_c('div',{style:({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '12px'
    })},[_c('h4',{style:({ margin: 0 })},[_vm._v("Documents")]),_c('b-button',{staticClass:"is-rounded",attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.addDocuments({ leaseID: _vm.leaseID })}}},[_vm._v(" Add Documents ")])],1),_c('PDFModal',{ref:"pdfModal"}),_c('div',{staticClass:"pb-5",style:({ position: 'relative', display: 'flex', flexWrap: 'wrap', width: '100%' })},[_vm._l((_vm.documents),function(doc,index){return _c('div',{key:index,style:({
        position: 'relative',
        width: '200px',
        height: '100px',
        background: '#FFF',
        borderRadius: '4px',
        margin: '10px',
        boxShadow: "0 0 4px 1px #E6E6E6"
      })},[_c('div',{style:({
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          overflow: 'hidden'
        })},[_c('PDF',{staticStyle:{"display":"none"},attrs:{"src":_vm.base64pdf},on:{"num-pages":function($event){_vm.pageCount = new Array($event)}}}),_vm._l((_vm.pageCount),function(page,index){return _c('PDF',{key:index,ref:'pdf-' + index,refInFor:true,style:({
            margin: 'auto',
            width: ((100 * 1) + "%"),
            opacity: _vm.loading ? 0 : 1,
            transition: _vm.transition ? '.2s opacity' : ''
          }),attrs:{"src":doc.content,"page":index + 1,"scale":1,"resize":true}})})],2),_c('b-tooltip',{style:({ position: 'absolute', top: '10px', left: '10px' }),attrs:{"label":"Delete"}},[_c('button',{staticClass:"delete",style:({ position: 'relative' }),attrs:{"type":"button"},on:{"click":function($event){return _vm.confirmEraseDocument({ index: index, doc: doc })}}})]),_c('b-tooltip',{attrs:{"label":"Preview"}},[_c('button',{staticClass:"view",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.openPDFModal(doc.documentId, doc.description)}}},[_c('span',{staticClass:"pl-6 href-overflow-wrap centerpreview",staticStyle:{"font-size":"8pt"}},[_vm._v(_vm._s(doc.description || 'Preview'))])])]),_c('b-tooltip',{style:({ position: 'absolute', top: '10px', right: '10px' }),attrs:{"label":"Download"}},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.downloadDocument({ index: index, doc: doc })}}},[_c('b-icon',{style:({ color: 'rgba(10, 10, 10, 0.2)' }),attrs:{"icon":"download","size":"is-small"}})],1)])],1)}),(_vm.documents.length === 0)?_c('div',{staticClass:"has-text-grey has-text-centered",style:({ padding: '20px' })},[_vm._v(" No documents added ")]):_vm._e(),_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }