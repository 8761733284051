/*
	import { parseErrors } from '@/utilities/response/parseErrors'

	var { errorMessage } = parseErrors ({ data });
*/

/*
	{
		"type": "https://tools.ietf.org/html/rfc7231#section-6.5.1",
		"title": "One or more validation errors occurred.",
		"status": 400,
		"traceId": "00-17e1a3b018cf1d478a3199d5143ebcf1-9eca96f5be7ff842-00",
		"errors": {
			"Description": [
				"Please enter up to 50 characters for the description."
			]
		}
	}

	->

	"Please enter up to 50 characters for the description."

*/

import _get from 'lodash/get'

export function parseErrors({ data }) {
  let errorMessage = []

  try {
    const errors = _get(data, 'errors', [])
    const keys = Object.keys(errors)

    if (keys.length >= 1) {
      for (let key in errors) {
        errorMessage.push(errors[key])
      }
    }
  } catch (x) {
    console.error(x)
  }

  return {
    errorMessage: errorMessage.join(', ')
  }
}
