export function mounted() {
  // unitTestID for testing purposes
  // there's probably a better way to mock this though...
  this.unitID = this.$route.params.id || this.unitTestID
  this.getRefreshedLeases()

  if (this.isDebug == true) {
    console.debug('mounted unitID=' + this.unitID)
  }

  this.resetForm()
}
