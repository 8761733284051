import { leaseDocumentStore } from '@/services/LeaseDocument/LeaseDocument/store'
import { notifyProblem } from '@/services/notify'

const messages = {
  1: 'No documents were added for upload.'
}

export const methods = {
  documentsUpdated({ documents }) {
    this.documents = documents
  },

  open({ id }) {
    this.id = id

    this.documents = []

    this.loading = false
    this.show = true
  },

  async upload() {
    this.loading = true

    if (!Array.isArray(this.documents) || this.documents.length === 0) {
      notifyProblem(messages[1])
      this.loading = false
      return
    }

    const leaseID = this.id
    const { problems } = await leaseDocumentStore.dispatch('storeMultiple', {
      leaseID,
      documents: this.documents
    })
    if (problems.length >= 1) {
      notifyProblem(`A problem occurred and ${problems.length} photos may not have been stored.`)
      this.loading = false
      return
    }

    this.onUpload()

    this.loading = false
    this.show = false
  }
}
