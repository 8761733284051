/*
  example:
    import { getLeaseDoc } from '@/services/LeaseDocument/LeaseDocument/GetDocumentFile';

    const { doc, successful, message } = await getLeaseDoc ({
      params: {
				leaseDocumentID,
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
//
export async function getLeaseDocument({ params }) {
  const message = 'The lease document could not be retrieved.'

  try {
    const doc = await kms.get(`/LeaseDocument/LeaseDocument/GetDocumentFile`, {
      params
    })

    return {
      successful: true,
      message: '',
      doc
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    doc: null
  }
}

///////
