/*
  example:
    import { storeLeaseDocument } from '@/services/LeaseDocument/LeaseDocument/StoreDocumentFile';

    const { result, successful, message } = await storeLeaseDocument ({
      document,
      params: {
				leaseID,

				// optional
				existingLeaseDocumentID
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import _get from 'lodash/get'
//
import kms from '@/services/kms'
//
export async function storeLeaseDocument({ params, document }) {
  var message = 'The lease document could not be stored.'

  try {
    const formData = new FormData()
    formData.append('file', document)

    console.log('formData', formData.get('file'))

    const result = await kms.post(`/LeaseDocument/LeaseDocument/StoreDocumentFile`, formData, {
      params,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    return {
      successful: true,
      message: '',
      result
    }
  } catch (exception) {
    console.log(exception.message)
    console.error(exception)

    let errorMessage = _get(exception, 'message', '')
    if (typeof errorMessage === 'string' && errorMessage.length >= 1) {
      message = errorMessage
    }
  }

  return {
    successful: false,
    message,
    result: null
  }
}

///////
