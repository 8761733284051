export const watch = {
  hoaId() {
    this.getRefreshedLeases()
  },
  selectedRow(row) {
    if (this.isDebug == true) console.debug('selected', { row })

    if (row && row != undefined) {
      this.formData.leaseID = row.leaseID
      this.formData.StartDate = new Date(this.formatDateFn(row.startDate))
      this.formData.EndDate = new Date(this.formatDateFn(row.endDate))
      this.formData.TerminationDate = new Date(this.formatDateFn(row.terminationDate))
      this.formData.Notes = row.notes
    }

    if (this.isDebug == true)
      console.debug('update formData values=' + JSON.stringify(this.formData))
  },
  activeTab() {
    if (this.activeTab === 0) {
      this.updateRows({
        table: 'activeTable',
        list: 'leaseList'
      })
    } else if (this.activeTab === 1) {
      this.updateRows({
        table: 'terminatedTable',
        list: 'terminatedLeaseList'
      })
    }
  },
  leaseList() {
    if (this.activeTab === 0) {
      this.updateRows({
        table: 'activeTable',
        list: 'leaseList'
      })
    }
  },
  terminatedLeaseList() {
    if (this.activeTab === 1) {
      this.updateRows({
        table: 'terminatedTable',
        list: 'terminatedLeaseList'
      })
    }
  }
}
