/*
  example:
    import { getLeaseDocumentData } from '@/services/LeaseDocument/LeaseDocument/Get';

    const { result, successful, message } = await getLeaseDocumentData ({
      leaseDocumentID
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import kms from '@/services/kms'
//
export async function getLeaseDocumentData({ leaseDocumentID }) {
  const message = 'The lease document data could not be retrieved.'

  try {
    const result = await kms.get(`/LeaseDocument/LeaseDocument/${leaseDocumentID}`)

    return {
      successful: true,
      message: '',
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    result: []
  }
}

///////
