import _get from 'lodash/get'
import { updateRows } from './list/updateRows'
import { showFormValidationErrors } from './list/showFormValidationErrors'
import { notifyMessage, notifyProblem } from '@/services/notify'
import dataToInteger from '@/utilities/data/toInteger'

export const methods = {
  updateRows,

  documentsUpdated() {
    console.log('documentsUpdated')
  },

  clear(field) {
    this.formData[field] = null

    if (field === 'startDate') {
      this.clearStartDate = true
    }

    if (field === 'endDate') {
      this.clearEndDate = true
    }

    if (field === 'terminationDate') {
      this.clearTerminationDate = true
    }
  },

  clearFormErrors() {
    const DEFAULT_FORM_ERRORS = this.DEFAULT_FORM_ERRORS
    this.formErrors = {
      ...DEFAULT_FORM_ERRORS
    }
  },

  openDocumentModal({ id }) {
    this.$refs.documentModal.open({
      id
    })
    this.loading = false
  },

  async resetForm() {
    this.loading = true

    const DEFAULT_FORM_VALUES = this.DEFAULT_FORM_VALUES
    this.formData = {
      ...DEFAULT_FORM_VALUES
    }

    this.clearFormErrors()

    if (this.$refs.form) {
      this.$refs.form.reset()
    }

    this.loading = false
  },

  setClass(leaseID) {
    if (this.isDebug == true) console.debug('...setClass=' + leaseID)

    if (this.selectedLeaseID == leaseID) {
      if (this.isDebug == true) console.debug('true')
      return 'is-selected'
    } else {
      if (this.isDebug == true) console.debug('false')
      return ''
    }
  },

  updateSelected({ id, list }) {
    try {
      this.selectedLease = this[list].find(v => {
        return v.leaseID === id
      })
    } catch (exception) {
      console.error(exception)
    }
  },

  initButtons() {
    this.selectedID = null
    this.formShow = false
    this.action = ''
    this.formData.LeaseTenantID = 0
  },

  showFormValidationErrors,

  // Lease methods
  confirmDeleteLease(id) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Lease',
      message: `Are you sure you want to <b>delete</b> this lease ID: ${id}?`,
      confirmText: 'Delete Lease',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteLease(id)
    })
  },

  openAddLeaseModal() {
    this.resetForm()
    this.selectedID = null
    this.action = 'add'
    this.formData.LeaseTenantID = 0
    this.showLeaseModal = true
  },

  getPayloadLease() {
    if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

    let problem = ''

    const startDate = this.formData.startDate
    if (typeof startDate !== 'string') {
      problem = 'Start Date is required.'
      this.$set(this.formErrors, 'startDate', 'The start date is required.')
      return null
    }

    const endDate = this.formData.endDate
    if (typeof endDate !== 'string') {
      problem += ' End Date is required.'
      this.$set(this.formErrors, 'endDate', 'The end date is required.')
      return null
    }

    const terminationDate = this.formData.terminationDate

    if (problem != '') {
      notifyProblem(problem)
      return null
    }

    const payload = {
      // _get 0 (falsy) -> default value
      leaseID: this.formData.leaseID,
      startDate,
      endDate,
      terminationDate,
      notes: _get(this, ['formData', 'Notes'], null),
      unitID: this.unitID
    }

    if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

    return payload
  },

  loadLease() {
    this.resetForm()
    this.selectedID = null
    this.formShow = !this.formShow
    this.action = this.action == '' ? 'add' : ''
    this.action === 'add' ? (this.clearTerminationDate = true) : false
    this.formData.LeaseTenantID = 0
  },

  loadUpdateLease(id) {
    if (this.isDebug == true) console.debug('loadUpdateLeaseID=' + id)

    this.formShow = false
    this.action = ''
    this.selectedID = null

    if (this.selectedLease && this.selectedLease != undefined) {
      this.formData.leaseID = _get(this, ['selectedLease', 'leaseID'], null)
      this.formData.Notes = this.selectedLease.notes

      this.formData.startDate = _get(this, ['selectedLease', 'startDate'], null)
      this.formData.endDate = _get(this, ['selectedLease', 'endDate'], null)
      this.formData.terminationDate = _get(this, ['selectedLease', 'terminationDate'], null)

      if (!this.formData.terminationDate && this.action === 'update') {
        this.clearTerminationDate = false
      }
    }

    this.selectedID = id
    this.formShow = true
    this.action = 'update'
    this.formData.LeaseTenantID = 0

    this.showLeaseModal = true
  },

  // Lease API calls
  async onFormSubmit() {
    await this.addLease()
  },

  async addLease() {
    if (this.isDebug == true) console.debug('in addLease unitID=' + this.unitID)

    const { unitID } = this
    const parsedUnitID = dataToInteger(unitID)

    if (typeof parsedUnitID !== 'number' || parsedUnitID <= 0) {
      notifyProblem('Unable to add this lease.')
      return
    }

    try {
      this.loading = true

      const leasePayload = this.getPayloadLease()
      if (leasePayload === null) {
        console.error(leasePayload)
        notifyProblem('Unable to add this lease.')
        this.loading = false
        return
      }

      const results = await this.kms.post(`/Lease/Lease`, leasePayload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      const leaseID = results.leaseID

      if (leaseID && leaseID > 0) {
        this.formComplete = true
        this.resetForm()
        this.getRefreshedLeases()
        notifyMessage(`Successfully added lease.`)
        this.showLeaseModal = false
      } else {
        notifyProblem('There was a problem adding the new lease.')
      }
    } catch (e) {
      notifyProblem(e)
    }

    this.loading = false
  },

  async updateLease() {
    if (!this.formData || !this.unitID) {
      notifyProblem('Unable to update this lease.')
      return
    }

    try {
      this.loading = true

      const results = await this.kms.put(`/Lease/Lease`, this.getPayloadLease())

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results && results.leaseID >= 0) {
        this.formComplete = true
        this.resetForm()
        this.getRefreshedLeases()

        notifyMessage(`The selected lease was successfully updated.`)

        this.showLeaseModal = false
      } else {
        notifyProblem('There was a problem updating this lease.')
      }
    } catch (e) {
      notifyProblem(e)
    }

    this.loading = false
  },

  async deleteLease(id) {
    if (id <= 0) {
      notifyProblem('Unable to delete this lease.')
      return
    }

    try {
      this.loading = true
      const data = { leaseID: id, forceDelete: false }
      const path = `/Lease/Lease/${id}?forceDelete=false`
      const results = await this.kms.delete(path, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results.recordID >= 0) {
        this.formComplete = true

        this.getRefreshedLeases()

        notifyMessage(`The selected lease was successfully deleted.`)
      } else {
        notifyProblem('There was a problem deleting this lease.')
      }
    } catch (e) {
      notifyProblem(e)
    }

    this.loading = false
  },

  async getRefreshedLeases() {
    if (this.unitID && this.unitID != undefined) {
      const params = {
        hoaID: this.hoaId,
        unitID: this.unitID
      }

      if (this.isDebug == true) console.debug('params=' + JSON.stringify(params))

      const { results } = await this.kms.get(`/Lease/Lease/List`, {
        params
      })

      this.leaseList = results.filter(i => i.terminationDate == null)
      this.terminatedLeaseList = results.filter(i => i.terminationDate != null)
      this.formData.LeaseTenantID = 0

      if (this.isDebug == true && 1 == 2) console.debug('results=' + JSON.stringify(this.leaseList))

      this.initButtons()
    }
  },

  // Lease tenant methods
  confirmDeleteLeaseTenant(id, name) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Lease Tenant',
      message: `Are you sure you want to <b>delete</b> this lease Tenant: ${name}?`,
      confirmText: 'Delete Lease Tenant',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteLeaseTenant(id)
    })
  },

  openTenantModal({ leaseID }) {
    this.resetForm()
    this.selectedID = null
    // this.formShow = !this.formShow
    // this.action = this.action == '' ? 'addtenant' : ''

    this.action = 'addtenant'
    this.formData.LeaseTenantID = 0
    // this.formData.LeaseID = leaseID ? leaseID : this.selectedLeaseID
    this.formData.LeaseID = leaseID

    this.showTenantModal = true
  },

  getPayloadTenant() {
    if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

    let notifyMessage = ''
    if (!this.formData.LeaseTenantName || this.formData.LeaseTenantName == undefined) {
      notifyMessage = 'Lease Tenant Name is required.'
    }

    if (!this.formData.LeaseID || this.formData.LeaseID == undefined) {
      notifyMessage += 'Lease ID is required.'
    }

    if (notifyMessage != '') {
      notifyProblem(notifyMessage)
      return
    }

    var email = _get(this, ['formData', 'LeaseTenantEmail'], null)
    if (typeof email === 'string') {
      email = email.toLowerCase()
    }

    const payload = {
      leaseTenantID: this.formData.LeaseTenantID,
      // leaseID: _get (this, [ 'formData', 'LeaseID' ],)
      leaseID:
        this.formData.LeaseID && this.formData.LeaseID != undefined && this.formData.LeaseID > 0
          ? this.formData.LeaseID
          : this.selectedLeaseID,
      name: _get(this, ['formData', 'LeaseTenantName'], null),
      email,
      phone: _get(this, ['formData', 'LeaseTenantPhone'], null)
    }

    if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

    return payload
  },

  loadUpdateLeaseTenant(id, tenant) {
    this.resetForm()

    this.selectedLeaseTenant = tenant

    if (tenant && tenant != undefined) {
      this.formData.LeaseTenantID = id
      this.formData.LeaseID = tenant.leaseID
      this.formData.LeaseTenantName = tenant.name
      this.formData.LeaseTenantEmail = tenant.email
      this.formData.LeaseTenantPhone = tenant.phone

      if (this.isDebug == true)
        console.debug('loadUpdateLeaseTenantID=' + id + ' tenant=' + JSON.stringify(this.formData))
    }

    this.selectedID = id
    this.formShow = true
    this.action = 'updatetenant'

    this.showTenantModal = true
  },

  // Lease tenant API calls
  async addLeaseTenant() {
    const LeaseID = this.formData.LeaseID

    if (typeof LeaseID !== 'number') {
      notifyProblem('The lease tenant could not be added.')
      return
    }

    try {
      this.loading = true

      let results = null
      try {
        results = await this.kms.post(`/LeaseTenant/LeaseTenant`, this.getPayloadTenant())
      } catch (exception) {
        this.showFormValidationErrors({
          exception
        })

        this.loading = false

        return
      }

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (results.leaseTenantID && results.leaseTenantID > 0) {
        this.formComplete = true
        this.resetForm()
        this.getRefreshedLeases()
        notifyMessage(`Successfully added lease tenant.`)

        this.showTenantModal = false
      } else {
        notifyProblem('There was a problem adding the new lease tenant.')
      }
    } catch (e) {
      notifyProblem(e)
    }

    this.loading = false
  },

  async updateLeaseTenant() {
    if (!this.formData || !this.formData.LeaseTenantID || this.formData.LeaseTenantID <= 0) {
      notifyProblem('Unable to update this lease tenant.')
      return
    }

    try {
      this.loading = true

      // const results = await this.kms.put(`/LeaseTenant/LeaseTenant`, this.getPayloadTenant())

      let results = null
      try {
        results = await this.kms.put(`/LeaseTenant/LeaseTenant`, this.getPayloadTenant())
      } catch (exception) {
        this.showFormValidationErrors({
          exception
        })

        this.loading = false

        return
      }

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results && results.leaseTenantID >= 0) {
        this.formComplete = true
        this.resetForm()
        this.getRefreshedLeases()

        notifyMessage(`The selected lease tenant was successfully updated.`)

        this.showTenantModal = false
      } else {
        notifyProblem('There was a problem updating this lease tenant.')
      }
    } catch (e) {
      notifyProblem(e)
    }
    this.loading = false
  },

  async deleteLeaseTenant(id) {
    if (id <= 0) {
      notifyProblem('Unable to delete this lease tenant.')
      return
    }

    try {
      this.loading = true
      const data = { leaseTenantID: id, forceDelete: false }
      const path = `/LeaseTenant/LeaseTenant/${id}?forceDelete=false`
      const results = await this.kms.delete(path, data)

      if (this.isDebug == true) console.debug(JSON.stringify(results))

      if (results.recordID >= 0) {
        this.formComplete = true

        this.getRefreshedLeases()

        notifyMessage(`The selected lease tenant was successfully deleted.`)
      } else {
        notifyProblem('There was a problem deleting this lease tenant.')
      }
    } catch (e) {
      notifyProblem(e)
    }

    this.loading = false
  }
}
