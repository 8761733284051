/*
  example:
    import { addLeaseDocument } from '@/services/LeaseDocument/LeaseDocument/Post';

    const { leaseDocumentID, successful, message } = await addLeaseDocument ({
      json: {
        leaseID,
        description: "file.txt"
      }
    });
    if (!successful) {
      console.error (message);
      return;
    }
*/
//
import _get from 'lodash/get'
//
import kms from '@/services/kms'
import { parseErrors } from '@/utilities/response/parseErrors'
//
export async function addLeaseDocument({ json }) {
  var message = 'The lease document data could not be added.'
  var leaseDocumentID = null

  const { resp, status, data, successful } = await kms.post2(`/LeaseDocument/LeaseDocument`, json)
  if (!successful) {
    console.log('addLeaseDocument', { data })

    var { errorMessage } = parseErrors({ data })
    if (typeof errorMessage === 'string' && errorMessage.length >= 1) {
      message = errorMessage
    }
  } else {
    leaseDocumentID = _get(data, ['leaseDocumentID'], '')
  }

  console.log({
    successful,
    data,
    status,
    resp
  })

  return {
    successful,
    message,
    leaseDocumentID
  }
}

///////
