<template>
  <div>
    <Modal :toggle.sync="show" :loading="loading">
      <PDFUploader :update="documentsUpdated" />

      <div :style="{ display: 'flex', justifyContent: 'end', paddingBottom: '12px 0' }">
        <b-button @click="upload" type="is-primary">Upload</b-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import PDFUploader from '@/components/Fields/PDFUploader'
import { methods } from './keys/methods'

export default {
  props: {
    onUpload: Function
  },

  components: {
    Modal,
    PDFUploader
  },

  data() {
    return {
      show: false,
      loading: false,
      id: null
    }
  },

  methods
}
</script>
